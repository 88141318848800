@charset "UTF-8";
/**
 * Fabricator styles
 * @author Luke Askew
 * Class selectors are namespaced with "f-"
 */
.f-menu .f-menu__heading {
  font-family: "Trade Gothic W01 Bold_2";
  font-weight: 500 !important;
}

/**
 * Github-like theme for Prism.js
 * @author Luke Askew http://github.com/lukeaskew
 */
code,
code[class*=language-],
pre[class*=language-] {
  color: #333;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  tab-size: 4;
  hyphens: none;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  line-height: 1.4;
  direction: ltr;
  cursor: text;
  letter-spacing: normal;
}

pre[class*=language-] {
  overflow: auto;
  margin: 1em 0;
  padding: 1.2em;
  border-radius: 3px;
  font-size: 85%;
}

p code,
li code,
table code {
  margin: 0;
  border-radius: 3px;
  padding: 0.2em 0;
  font-size: 85%;
}
p code:before, p code:after,
li code:before,
li code:after,
table code:before,
table code:after {
  letter-spacing: -0.2em;
  content: "\A0";
}

code,
:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #f7f7f7;
}

:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #969896;
}
.token.punctuation, .token.string, .token.atrule, .token.attr-value {
  color: #183691;
}
.token.property, .token.tag {
  color: #63a35c;
}
.token.boolean, .token.number {
  color: #0086b3;
}
.token.selector, .token.attr-name, .token.attr-value .punctuation:first-child, .token.keyword, .token.regex, .token.important {
  color: #a71d5d;
}
.token.operator, .token.entity, .token.url, .language-css .token.string {
  color: #a71d5d;
}
.token.entity {
  cursor: help;
}

.namespace {
  opacity: 0.7;
}

.f-color-chips {
  display: flex;
  flex-wrap: wrap;
}

.f-color-chip {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  border-top-width: 8em;
  border-top-style: solid;
  border-bottom-width: 0;
  border-bottom-style: solid;
  background-color: #fff;
  font-weight: 700;
  font-size: 0.75em;
  padding: 1em;
  margin-bottom: 2em;
  box-sizing: border-box;
}
@media (min-width: 60em) {
  .f-color-chip {
    flex-basis: 13em;
  }
}
.f-color-chip .f-color-chip__color {
  background-color: #fff;
  content: attr(data-color);
  font-weight: 400;
}

.f-controls {
  box-sizing: border-box;
}
.f-controls:after {
  clear: both;
}
.f-controls:before, .f-controls:after {
  display: table;
  content: " ";
}
.f-controls .f-control {
  display: block;
  float: left;
  text-align: center;
  width: 33.3333333333%;
  margin: 0;
  padding: 1rem 0;
}
.f-controls .f-control.f-active {
  box-shadow: inset 0 0.25rem 0 0 #b30738;
}
.f-controls .f-control.f-active use {
  fill: hsl(0, 0%, 46%);
}
.f-controls .f-control svg {
  vertical-align: middle;
}
.f-controls .f-control svg use {
  fill: hsl(0, 0%, 80%);
}

.f-control {
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  margin-left: 0.66rem;
}
.f-control:first-child {
  margin-left: 0;
}
.f-control svg {
  width: 0.875rem;
  height: 0.875rem;
}
.f-control svg use {
  fill: hsl(0, 0%, 80%);
}

.f-control-bar {
  padding: 1rem 0;
}
.f-control-bar:after {
  clear: both;
}
.f-control-bar:before, .f-control-bar:after {
  display: table;
  content: " ";
}

.f-menu-toggle {
  cursor: pointer;
  vertical-align: middle;
}
.f-menu-toggle svg {
  display: block;
  float: left;
  margin-bottom: -1px;
}

.f-item-group {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid hsl(0, 0%, 80%);
}
.f-item-group:after {
  clear: both;
}
.f-item-group:before, .f-item-group:after {
  display: table;
  content: " ";
}
.f-item-group:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.f-item-group ~ .f-item-group {
  margin-top: 0;
}
.f-item-group > .f-item-group {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 0;
}
.f-item-group > .f-item-group:first-of-type {
  margin-top: 0;
}
.f-item-group > .f-item-group:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.f-item-code {
  margin-top: 2rem;
}

.f-item-preview:after {
  clear: both;
}
.f-item-preview:before, .f-item-preview:after {
  display: table;
  content: " ";
}

.f-item-border-bottom {
  border-bottom: 1px solid hsl(0, 0%, 80%);
}

.f-item-heading-group {
  margin-bottom: 2rem;
  vertical-align: middle;
}
.f-item-heading-group:after {
  clear: both;
}
.f-item-heading-group:before, .f-item-heading-group:after {
  display: table;
  content: " ";
}

.f-item-heading {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.f-item-controls {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.f-item-controls .f-control {
  width: 0.875rem;
  height: 0.875rem;
  display: block;
  float: left;
}
.f-item-controls .f-control:hover use {
  fill: hsl(0, 0%, 46%);
}
.f-item-controls .f-control use {
  fill: hsl(0, 0%, 80%);
}

.f-item-hidden {
  display: none;
}

.f-item-notes {
  font-size: 0.875rem;
}

html {
  height: 100%;
}
html.f-menu-active {
  overflow: hidden;
}
@media (min-width: 60em) {
  html.f-menu-active {
    overflow: auto;
  }
}

body {
  margin: 0;
  position: relative;
}
.f-menu-active body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (min-width: 60em) {
  .f-menu-active body {
    overflow: auto;
    height: auto;
  }
}

.f-container {
  box-sizing: border-box;
  position: relative;
  padding: 0 1em;
  z-index: 0;
  min-height: 100vh;
}
.f-container:after {
  clear: both;
}
.f-container:before, .f-container:after {
  display: table;
  content: " ";
}
.f-menu-active .f-container {
  transform: translate(14rem, 0);
}
@media (min-width: 60em) {
  .f-menu-active .f-container {
    margin-left: 14rem;
    transform: translate(0, 0);
  }
}

.f-menu {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-14rem, 0);
  width: 14rem;
  height: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.f-menu-active .f-menu {
  transform: translate(0, 0);
}
.f-menu ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}
.f-menu > ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.f-menu > ul > li {
  margin-top: 1rem;
}
.f-menu li {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}
.f-menu a {
  display: block;
  padding: 0.33rem 2rem;
  color: hsl(0, 0%, 46%) !important;
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.5;
}
.f-menu a:hover {
  color: hsl(0, 0%, 46%) !important;
  text-decoration: underline;
}
.f-menu a.f-active {
  box-shadow: inset 0.25rem 0 0 0 #b30738;
}
.f-menu .f-menu__heading {
  padding-left: 1.5rem;
  font-weight: 700;
  font-size: 0.875rem;
}
.f-menu .f-menu__heading:hover {
  color: hsl(0, 0%, 46%);
}